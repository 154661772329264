import React, { useCallback, useRef } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  Container,
  Heading,
  Stack,
  Text,
  Box,
  Grid,
  GridItem,
  Image,
  Button,
  Link,
} from "@chakra-ui/react"
import { BsChevronDoubleDown } from "react-icons/bs"
import SellingForm from "../components/form/selling-form"
import { BsFillTelephoneFill } from "react-icons/bs"

interface Props {
  data: {
    allContentfulCompanyProfile: {
      edges: any[]
    }
    allContentfulSiteImages: {
      edges: any[]
    }
  }
}

const Sales: React.FC<Props> = ({ data }) => {
  const url = data.allContentfulSiteImages.edges[0].node.image?.file?.url
  const salesImage = data.allContentfulSiteImages.edges[1].node.image?.file?.url
  const scrollEndpoint: any = useRef(null)

  const scrollToForm = useCallback(() => {
    scrollEndpoint!.current!.scrollIntoView({
      behavior: "smooth",
      block: "center",
    })
  }, [scrollEndpoint])

  return (
    <Layout>
      <Seo title="売る" description="売る" image={url} />
      <Box h={`8vh`} backgroundSize="cover"></Box>
      <Container maxW="container.xl" as={`main`} p={0}>
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }}
          gap={0}
        >
          <GridItem w="100%" bg="blue.50">
            <Stack
              py={{ base: 12, lg: 12 }}
              pt={{ base: 12, lg: 24 }}
              px={{ base: 8, lg: 24 }}
              spacing={{ base: 4, lg: 8 }}
            >
              <Heading
                fontWeight={`bold`}
                color={`blue.500`}
                lineHeight={1.8}
                fontSize={{ base: 30, lg: 40 }}
                textAlign={`center`}
              >
                伊豆エリアの
                <Box as={`br`} />
                <Text as={`span`} fontSize={{ base: 50, lg: 64 }}>
                  不動産売却
                </Text>
                <Box as={`br`} />
                お任せください
              </Heading>
              <Text
                lineHeight={2.2}
                fontSize={{ base: 14, lg: 18 }}
                fontWeight={`bold`}
                textAlign={`center`}
              >
                伊豆エリアに特化した
                <Text as={`span`} color={`blue.500`}>
                  地域密着型のサービス
                </Text>
                <Box as={`br`} display={{ base: "none", lg: "block" }} />
                <Text as={`span`} color={`blue.500`}>
                  経験豊富な担当者
                </Text>
                がご対応いたします。
              </Text>
              <Button
                color={`white`}
                bg={`blue.500`}
                size={`lg`}
                fontSize={{ base: 14, lg: 16 }}
                rounded={40}
                _hover={{ bg: "blue.400", color: "white" }}
                onClick={scrollToForm}
              >
                お問い合わせはこちら
              </Button>
            </Stack>
          </GridItem>
          <GridItem w="100%" bg="blue.500">
            <Image
              src={salesImage}
              h={{ base: "250px", lg: `670px` }}
              w={`100%`}
              objectFit="cover"
            />
          </GridItem>
        </Grid>
        <Stack
          py={{ base: 12, lg: 24 }}
          align={`center`}
          px={{ base: 4, lg: 20 }}
          spacing={{ base: 4, lg: 12 }}
        >
          <Heading
            textAlign={`center`}
            borderBottom={`1px`}
            borderColor={`blackAlpha.300`}
            w={{ base: "100%", lg: `60%` }}
            fontSize={{ base: 18, lg: 24 }}
            fontWeight={`thin`}
            pb={4}
          >
            こんなお悩みはありませんか？
          </Heading>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(3, 1fr)" }}
            gap={6}
            w={`100%`}
          >
            <GridItem
              w="100%"
              bg={`blue.50`}
              p={8}
              fontSize={{ base: 16, lg: 22 }}
              textAlign={`center`}
              fontWeight={`bold`}
              rounded={10}
            >
              どうやって
              <Box as={`br`} />
              売ればいいの
              <Text as={`span`} color={`red`} fontSize={{ base: 18, lg: 30 }}>
                ？
              </Text>
            </GridItem>
            <GridItem
              w="100%"
              bg={`blue.50`}
              p={8}
              fontSize={{ base: 16, lg: 22 }}
              textAlign={`center`}
              fontWeight={`bold`}
              rounded={10}
            >
              交渉・やりとりが
              <Box as={`br`} />
              分からない
              <Text as={`span`} color={`red`} fontSize={{ base: 18, lg: 30 }}>
                ！
              </Text>
            </GridItem>
            <GridItem
              w="100%"
              bg={`blue.50`}
              p={8}
              fontSize={{ base: 16, lg: 22 }}
              textAlign={`center`}
              fontWeight={`bold`}
              rounded={10}
            >
              できるだけ
              <Box as={`br`} />
              高く売りたい
              <Text as={`span`} color={`red`} fontSize={{ base: 18, lg: 30 }}>
                ！
              </Text>
            </GridItem>
          </Grid>
          <BsChevronDoubleDown size={30} />
          <Heading
            textAlign={`center`}
            w={`100%`}
            fontSize={{ base: 26, lg: 50 }}
            color={`blue.500`}
            fontWeight={`bold`}
            lineHeight={1.6}
            pb={4}
          >
            <Text as={`span`} fontSize={{ base: 16, lg: 30 }}>
              サイファーム南伊豆にお任せください！
            </Text>
            <Box as={`br`} />
            私たちが
            <Text as={`span`} fontSize={{ base: 40, lg: 64 }}>
              解決
            </Text>
            します
          </Heading>
          <Image src={url} alt={`illustration`} />
        </Stack>
        <Stack
          py={{ base: 8, lg: 20 }}
          align={`center`}
          px={{ base: 4, lg: 20 }}
          spacing={6}
          bg={`blue.500`}
        >
          <Text
            textAlign={`center`}
            py={3}
            w={{ base: "100%", lg: `40%` }}
            fontSize={{ base: 18, lg: 20 }}
            fontWeight={`bold`}
            color={`blue.500`}
            bg={`white`}
          >
            不動産売却をご希望の方
          </Text>
          <Heading
            textAlign={`center`}
            w={{ base: "100%", lg: `60%` }}
            fontSize={{ base: 20, lg: 34 }}
            fontWeight={`bold`}
            color={`white`}
            lineHeight={1.8}
            py={6}
          >
            まずはお気軽にご相談ください
            <Box as={`br`} />
            <Link onClick={scrollToForm} fontSize={20}>
              <u>お問い合わせはこちら！</u>
            </Link>
          </Heading>
          <Text
            fontWeight="bold"
            color={`white`}
            fontSize={{ base: 10, lg: 14 }}
            textAlign="center"
          >
            お問い合わせは、お電話でも承ります。お気軽にご連絡ください。
          </Text>
          <Text
            mb={{ base: 0, lg: 10 }}
            fontWeight="bold"
            color={`white`}
            fontSize={{ base: 20, lg: 30 }}
            textAlign="center"
          >
            <Stack direction={`row`} justify="center" align="center">
              <BsFillTelephoneFill />
              <Link href="tel:+81-558-64-8880" letterSpacing={1.8}>
                0558-64-8880
              </Link>
            </Stack>
          </Text>
          <Box>
            <Text
              as={`span`}
              fontSize={{ base: 16, lg: 18 }}
              fontWeight="bold"
              color={`blue.500`}
              bg={`white`}
              lineHeight={2}
              p={2}
              m={{ base: 0, lg: 3 }}
              rounded={5}
              textAlign={`center`}
            >
              受付時間
            </Text>
            <Box as={`br`} display={{ base: "block", lg: "none" }} />
            <Text
              as={`span`}
              fontSize={{ base: 20, lg: 20 }}
              lineHeight={2}
              fontWeight="bold"
              color={`white`}
              textAlign={`center`}
            >
              9時～18時
            </Text>
            <Box as={`br`} display={{ base: "block", lg: "none" }} />
            <Text
              as={`span`}
              ml={2}
              fontSize={{ base: 8, lg: 12 }}
              color={`white`}
              textAlign={`center`}
            >
              ※年末年始を除く
            </Text>
          </Box>
          <Button
            color={`white`}
            bg={`blue.300`}
            size={`lg`}
            w={{ base: "100%", lg: `30%` }}
            fontSize={{ base: 14, lg: 16 }}
            rounded={40}
            _hover={{ bg: "blue.400", color: "white" }}
            onClick={scrollToForm}
          >
            お問い合わせはこちら
          </Button>
        </Stack>
        <Stack
          py={{ base: 8, lg: 20 }}
          align={`center`}
          px={{ base: 4, lg: 20 }}
          spacing={{ base: 4, lg: 20 }}
          pb={{ base: 8, lg: 24 }}
        >
          <Heading
            textAlign={`center`}
            borderBottom={`1px`}
            borderColor={`blackAlpha.300`}
            w={{ base: "100%", lg: `60%` }}
            fontSize={{ base: 24, lg: 30 }}
            fontWeight={`bold`}
            pb={4}
          >
            不動産売却までの流れ
          </Heading>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(3, 1fr)" }}
            gap={{ base: 4, lg: 12 }}
            w={`100%`}
          >
            <GridItem
              w="100%"
              bg={`blue.50`}
              pt={{ base: 8, lg: 12 }}
              px={{ base: 4, lg: 8 }}
              pb={{ base: 8, lg: 16 }}
              rounded={10}
            >
              <Heading
                as={`h3`}
                fontSize={{ base: 16, lg: 20 }}
                fontWeight={`bold`}
                textAlign={`center`}
                lineHeight={1.6}
                color={`blue.500`}
              >
                ① 不動産売却の相談
              </Heading>
              <Text
                fontSize={{ base: 13, lg: 18 }}
                textAlign={`center`}
                mt={8}
                lineHeight={2}
              >
                売却相談のご依頼を
                <Link color={`blue.500`} onClick={scrollToForm}>
                  <u>こちら</u>
                </Link>
                からお送りください。
              </Text>
            </GridItem>
            <GridItem
              w="100%"
              bg={`blue.50`}
              pt={{ base: 8, lg: 12 }}
              px={{ base: 4, lg: 8 }}
              pb={{ base: 8, lg: 16 }}
              rounded={10}
            >
              <Heading
                as={`h3`}
                fontSize={{ base: 16, lg: 20 }}
                fontWeight={`bold`}
                textAlign={`center`}
                lineHeight={1.6}
                color={`blue.500`}
              >
                ② 売りたい物件の確認・調査を受ける
              </Heading>
              <Text
                fontSize={{ base: 13, lg: 18 }}
                textAlign={`center`}
                mt={8}
                lineHeight={2}
              >
                後日担当者よりご連絡し、ご都合の合う日程でお客様の物件を拝見させていただきます。
              </Text>
            </GridItem>
            <GridItem
              w="100%"
              bg={`blue.50`}
              pt={{ base: 8, lg: 12 }}
              px={{ base: 4, lg: 8 }}
              pb={{ base: 8, lg: 16 }}
              rounded={10}
            >
              <Heading
                as={`h3`}
                fontSize={{ base: 16, lg: 20 }}
                fontWeight={`bold`}
                textAlign={`center`}
                lineHeight={1.6}
                color={`blue.500`}
              >
                ③ 売買契約・売り出し価格を決める
              </Heading>
              <Text
                fontSize={{ base: 13, lg: 18 }}
                textAlign={`center`}
                mt={8}
                lineHeight={2}
              >
                売却内容についてご相談させて頂き、売買契約を結びます。
              </Text>
            </GridItem>
            <GridItem
              w="100%"
              bg={`blue.50`}
              pt={{ base: 8, lg: 12 }}
              px={{ base: 4, lg: 8 }}
              pb={{ base: 8, lg: 16 }}
              rounded={10}
            >
              <Heading
                as={`h3`}
                fontSize={20}
                fontWeight={`bold`}
                textAlign={`center`}
                lineHeight={1.6}
                color={`blue.500`}
              >
                ④ 見学客を迎える
              </Heading>
              <Text
                fontSize={{ base: 13, lg: 18 }}
                textAlign={`center`}
                mt={8}
                lineHeight={2}
              >
                担当者が購入希望者を探索し、物件を見学してもらいます。
              </Text>
            </GridItem>
            <GridItem
              w="100%"
              bg={`blue.50`}
              pt={{ base: 8, lg: 12 }}
              px={{ base: 4, lg: 8 }}
              pb={{ base: 8, lg: 16 }}
              rounded={10}
            >
              <Heading
                as={`h3`}
                fontSize={{ base: 16, lg: 20 }}
                fontWeight={`bold`}
                textAlign={`center`}
                lineHeight={1.6}
                color={`blue.500`}
              >
                ⑤ 売買契約・引き渡し
              </Heading>
              <Text
                fontSize={{ base: 13, lg: 18 }}
                textAlign={`center`}
                mt={8}
                lineHeight={2}
              >
                売主様・買主様間で売買契約を締結します。決済・物件の引き渡しを行います。
              </Text>
            </GridItem>
          </Grid>
        </Stack>
        <Stack
          py={{ base: 8, lg: 20 }}
          align={`center`}
          px={{ base: 4, lg: 20 }}
          spacing={4}
          bg={`blue.50`}
        >
          <Heading
            textAlign={`center`}
            color={`blue.500`}
            w={{ base: "100%", lg: `60%` }}
            fontSize={{ base: 42, lg: 80 }}
            fontWeight={`bold`}
          >
            Q
            <Text as={`span`} fontSize={{ base: 34, lg: 60 }}>
              &
            </Text>
            A
          </Heading>
          <Heading
            textAlign={`center`}
            borderBottom={`1px`}
            borderColor={`blackAlpha.300`}
            w={{ base: "100%", lg: `60%` }}
            fontSize={{ base: 24, lg: 30 }}
            fontWeight={`bold`}
            pb={4}
          >
            よくあるご質問
          </Heading>
          <Stack py={12} w={`100%`} spacing={12}>
            <Stack
              alignItems={"start"}
              spacing={4}
              bg={`white`}
              p={8}
              rounded={10}
            >
              <Heading
                fontSize={{ base: 16, lg: 24 }}
                lineHeight={2}
                fontWeight={`bold`}
              >
                <Text
                  as={`span`}
                  fontWeight={`bold`}
                  color={`white`}
                  bg={`blue.500`}
                  py={1}
                  px={2.5}
                  mr={5}
                >
                  Q
                </Text>
                売却をお願いした場合、どのようなことをしてくれますか？
              </Heading>
              <Text
                fontSize={{ base: 15, lg: 22 }}
                lineHeight={2}
                fontWeight={`bold`}
                color={`blue.500`}
              >
                <Text
                  as={`span`}
                  fontWeight={`bold`}
                  color={`blue.500`}
                  bg={`white`}
                  border={`1px`}
                  borderColor={`blue.500`}
                  py={1}
                  px={2.5}
                  mr={5}
                >
                  A
                </Text>
                お預かりした物件情報を幅広く広告掲載し、販売活動いたします。住宅雑誌・ホームページへの掲載、近隣の不動産会社への紹介をし、購入希望者様にご紹介いたします。既に当社にご相談頂いている購入希望者への物件紹介も併せて行います。販売に結びつく広告のノウハウを持った当社にお任せください。
              </Text>
            </Stack>
            <Stack alignItems={"start"} spacing={4} p={8} rounded={10}>
              <Heading
                fontSize={{ base: 16, lg: 24 }}
                lineHeight={2}
                fontWeight={`bold`}
              >
                <Text
                  as={`span`}
                  fontWeight={`bold`}
                  color={`white`}
                  bg={`blue.500`}
                  py={1}
                  px={2.5}
                  mr={5}
                >
                  Q
                </Text>
                相談にお金はかかりますか？
              </Heading>
              <Text
                fontSize={{ base: 15, lg: 22 }}
                lineHeight={2}
                fontWeight={`bold`}
                color={`blue.500`}
              >
                <Text
                  as={`span`}
                  fontWeight={`bold`}
                  color={`blue.500`}
                  bg={`white`}
                  border={`1px`}
                  borderColor={`blue.500`}
                  py={1}
                  px={2.5}
                  mr={5}
                >
                  A
                </Text>
                ご相談は全て無料で承っております。お気軽にご利用ください。
              </Text>
            </Stack>
            <Stack
              alignItems={"start"}
              spacing={4}
              bg={`white`}
              p={8}
              rounded={10}
            >
              <Heading
                fontSize={{ base: 16, lg: 24 }}
                lineHeight={2}
                fontWeight={`bold`}
              >
                <Text
                  as={`span`}
                  fontWeight={`bold`}
                  color={`white`}
                  bg={`blue.500`}
                  py={1}
                  px={2.5}
                  mr={5}
                >
                  Q
                </Text>
                相談後はすぐに売却しなければいけないですか？
              </Heading>
              <Text
                fontSize={{ base: 15, lg: 22 }}
                lineHeight={2}
                fontWeight={`bold`}
                color={`blue.500`}
              >
                <Text
                  as={`span`}
                  fontWeight={`bold`}
                  color={`blue.500`}
                  bg={`white`}
                  border={`1px`}
                  borderColor={`blue.500`}
                  py={1}
                  px={2.5}
                  mr={5}
                >
                  A
                </Text>
                いいえ。ご都合に合わせて売却時期を設定できます。まずはお気軽にご相談ください。
              </Text>
            </Stack>
          </Stack>
        </Stack>
        <Stack align={`center`} ref={scrollEndpoint}>
          <SellingForm />
        </Stack>
      </Container>
    </Layout>
  )
}

export default Sales

export const query = graphql`
  {
    allContentfulSiteImages(
      filter: { location: { eq: "sales" } }
      sort: { order: ASC, fields: imageNo }
      limit: 2
    ) {
      edges {
        node {
          image {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulCompanyProfile {
      edges {
        node {
          name
          address {
            address
          }
          tel
          email
          capital
          representativeName
          establishedOn
          license
          community
          workingHour
          holidays
        }
      }
    }
  }
`
