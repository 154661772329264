import React, { useEffect } from "react"
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  Wrap,
  WrapItem,
  Tag,
} from "@chakra-ui/react"
import { useForm, FormProvider, Controller } from "react-hook-form"
import Select from "react-select"
import axios from "axios"
import { navigate } from "gatsby"

type FormData = {
  kind: string
  prefecture: string
  city: any
  address: string
  isLiving: string
  name: string
  email: string
  tel: string
}

const SellingForm = () => {
  const methods = useForm<FormData>()

  const { cities } = require("./json/cities/shizuoka.json")
  const cityOptions = cities.map((city: any) => ({
    value: city.key,
    label: city.name,
  }))

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods

  useEffect(() => {
    setValue("prefecture", "静岡県")
    setValue("isLiving", "空家（空地）")
    setValue("kind", "一戸建て")
  }, [])

  useEffect(() => {
    if (cities) {
      setValue("city", [cityOptions[0]])
    }
  }, [cities])

  const onSubmit = async (data: FormData) => {
    try {
      const {
        data: { next, ok },
      } = await axios.post("https://formspree.io/f/mzbkvjwn", {
        ...data,
      })

      if (ok) {
        navigate(next)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Stack w={{ base: "100%", lg: `80%` }} spacing={0}>
      <Heading
        textAlign={`center`}
        p={4}
        mt={{ base: 0, lg: 10 }}
        fontWeight={`bold`}
        bg={`blue.500`}
        fontSize={{ base: 18, lg: 24 }}
        color={`white`}
      >
        不動産の情報を入力してください
      </Heading>
      <Stack>
        <FormProvider {...methods}>
          <Box as={`form`} onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={{ base: "column", lg: `row` }} spacing={0}>
              <Box
                bg={`blue.50`}
                w={{ base: "100%", lg: `478px` }}
                borderRight={`2px`}
                borderColor={`gray.200`}
              >
                <Text
                  py={{ base: 8, lg: 12 }}
                  px={{ base: 4, lg: 12 }}
                  fontWeight={`bold`}
                  fontSize={{ base: 16, lg: 20 }}
                  textAlign={{ base: `left`, lg: `center` }}
                >
                  お客様情報
                </Text>
              </Box>
              <Stack
                bg={`blue.50`}
                w={`100%`}
                py={{ base: 4, lg: 8 }}
                px={{ base: 4, lg: 8 }}
                spacing={{ base: 8, lg: 8 }}
                pb={20}
              >
                <FormControl isInvalid={!!errors.name}>
                  <FormLabel htmlFor="name" fontWeight={`bold`}>
                    氏名{" "}
                    <Tag
                      size={`sm`}
                      variant={"outline"}
                      colorScheme={"red"}
                      fontWeight={`bold`}
                      p={0.5}
                      rounded={2}
                    >
                      必須
                    </Tag>
                  </FormLabel>
                  <Input
                    id="name"
                    placeholder="例）伊豆太郎"
                    bg={`white`}
                    color={`gray.500`}
                    w={{ base: "100%", lg: `200px` }}
                    border={"2px"}
                    borderColor={`gray.200`}
                    {...register("name", {
                      required: "氏名を入力してください",
                    })}
                  />

                  <FormErrorMessage>
                    {errors.name && errors.name.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.tel}>
                  <FormLabel htmlFor="tel" fontWeight={`bold`}>
                    電話番号{" "}
                    <Tag
                      size={`sm`}
                      variant={"outline"}
                      colorScheme={"gray"}
                      fontWeight={`bold`}
                      p={0.5}
                      rounded={2}
                    >
                      任意
                    </Tag>
                  </FormLabel>
                  <Input
                    id="tel"
                    placeholder="例）09012345678"
                    bg={`white`}
                    color={`gray.500`}
                    w={{ base: "100%", lg: `200px` }}
                    border={"2px"}
                    borderColor={`gray.200`}
                    {...register("tel", {
                      // required: "電話番号を入力してください",
                    })}
                  />

                  <FormErrorMessage>
                    {errors.tel && errors.tel.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.email}>
                  <FormLabel htmlFor="email" fontWeight={`bold`}>
                    メールアドレス{" "}
                    <Tag
                      size={`sm`}
                      variant={"outline"}
                      colorScheme={"red"}
                      fontWeight={`bold`}
                      p={0.5}
                      rounded={2}
                    >
                      必須
                    </Tag>
                  </FormLabel>
                  <Input
                    id="email"
                    placeholder="例）saifarm@example.com"
                    bg={`white`}
                    color={`gray.500`}
                    w={{ base: "100%", lg: `250px` }}
                    border={"2px"}
                    borderColor={`gray.200`}
                    {...register("email", {
                      required: "メールアドレスを入力してください",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "不正なメールフォーマットです",
                      },
                    })}
                  />

                  <FormErrorMessage>
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </Stack>
            <Stack
              direction={{ base: "column", lg: `row` }}
              spacing={0}
              borderTop={`2px`}
              borderColor={`gray.200`}
            >
              <Box
                bg={`blue.50`}
                w={{ base: "100%", lg: `485px` }}
                borderRight={`2px`}
                borderRightColor={`gray.200`}
              >
                <Text
                  py={{ base: 8, lg: 12 }}
                  px={{ base: 4, lg: 12 }}
                  fontWeight={`bold`}
                  fontSize={{ base: 16, lg: 20 }}
                  textAlign={{ base: `left`, lg: `center` }}
                >
                  不動産の
                  <Text
                    as={`span`}
                    color={`blue.500`}
                    fontWeight={`bold`}
                    fontSize={{ base: 22, lg: 28 }}
                  >
                    『種類』
                  </Text>
                </Text>
              </Box>
              <Box
                bg={`blue.50`}
                w={`100%`}
                py={{ base: 4, lg: 12 }}
                px={{ base: 4, lg: 12 }}
              >
                <FormControl isInvalid={!!errors.kind}>
                  <Controller
                    control={control}
                    name="kind"
                    rules={{ required: "物件の種類を選択してください" }}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <Wrap spacing={8}>
                          <WrapItem>
                            <Radio size={`md`} bg={`white`} value="一戸建て">
                              一戸建て
                            </Radio>
                          </WrapItem>
                          <WrapItem>
                            <Radio size={`md`} bg={`white`} value="土地">
                              土地
                            </Radio>
                          </WrapItem>
                          <WrapItem>
                            <Radio size={`md`} bg={`white`} value="土地">
                              その他
                            </Radio>
                          </WrapItem>
                        </Wrap>
                      </RadioGroup>
                    )}
                  />
                  <FormErrorMessage mt={4}>
                    {errors.kind && errors.kind.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            </Stack>
            <Stack
              direction={{ base: "column", lg: `row` }}
              spacing={0}
              borderTop={`2px`}
              borderColor={`gray.200`}
            >
              <Box
                bg={`blue.50`}
                w={{ base: "100%", lg: `478px` }}
                borderRight={`2px`}
                borderColor={`gray.200`}
              >
                <Text
                  py={{ base: 8, lg: 12 }}
                  px={{ base: 4, lg: 12 }}
                  fontWeight={`bold`}
                  fontSize={{ base: 16, lg: 20 }}
                  textAlign={{ base: `left`, lg: `center` }}
                >
                  不動産の
                  <Text
                    as={`span`}
                    color={`blue.500`}
                    fontWeight={`bold`}
                    fontSize={{ base: 22, lg: 28 }}
                  >
                    『所在地』
                  </Text>
                </Text>
              </Box>
              <Stack
                bg={`blue.50`}
                w={`100%`}
                py={{ base: 4, lg: 8 }}
                px={{ base: 4, lg: 8 }}
                spacing={{ base: 4, lg: 8 }}
                pb={20}
              >
                <FormControl isInvalid={!!errors.prefecture}>
                  <FormLabel htmlFor="prefecture" fontWeight={`bold`}>
                    都道府県
                  </FormLabel>
                  <Input
                    id="prefecture"
                    placeholder="prefecture"
                    w={{ base: "100%", lg: `200px` }}
                    border={0}
                    readOnly={true}
                    bg={`gray.200`}
                    color={`gray.500`}
                    {...register("prefecture", {
                      required: "都道府県を選択してください",
                    })}
                  />
                  <FormErrorMessage>
                    {errors.prefecture && errors.prefecture.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.city}
                  w={{ base: "100%", lg: `400px` }}
                >
                  <FormLabel htmlFor="city" fontWeight={`bold`}>
                    市区町村{" "}
                    <Tag
                      size={`sm`}
                      variant={"outline"}
                      colorScheme={"red"}
                      fontWeight={`bold`}
                      p={0.5}
                      rounded={2}
                    >
                      必須
                    </Tag>
                  </FormLabel>
                  <Controller
                    name="city"
                    control={control}
                    rules={{ required: "市区町村を選択してください" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        defaultValue={[cityOptions[0]]}
                        options={cityOptions}
                      />
                    )}
                  />

                  <FormErrorMessage>
                    {errors.city && errors.city.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.address}>
                  <FormLabel htmlFor="address" fontWeight={`bold`}>
                    住所{" "}
                    <Tag
                      size={`sm`}
                      variant={"outline"}
                      colorScheme={"red"}
                      fontWeight={`bold`}
                      p={0.5}
                      rounded={2}
                    >
                      必須
                    </Tag>
                  </FormLabel>
                  <Textarea
                    id="address"
                    placeholder="例）〇〇町 1-1-1"
                    bg={`white`}
                    color={`gray.500`}
                    border={"2px"}
                    borderColor={`gray.200`}
                    {...register("address", {
                      required: "住所を入力してください",
                    })}
                  />

                  <FormErrorMessage>
                    {errors.address && errors.address.message}
                  </FormErrorMessage>
                </FormControl>
                {/* <FormControl>
                  <FormLabel htmlFor="building" fontWeight={`bold`}>建物名 号室</FormLabel>
                  <Stack direction={`row`} spacing={4} align={`center`}>
                    <Input
                      id="prefecture"
                      placeholder="Saifarmマンション"
                      w={`400px`}
                      border={"2px"}
                      borderColor={`gray.200`}
                      bg={`white`}
                      color={`gray.500`}
                      {...register("building")}
                    />
                    <Input
                      id="prefecture"
                      placeholder="101"
                      w={`150px`}
                      border={"2px"}
                      borderColor={`gray.200`}
                      bg={`white`}
                      color={`gray.500`}
                      {...register("room")}
                    />
                    <Text>号室</Text>
                  </Stack>
                </FormControl> */}
                <FormControl isInvalid={!!errors.isLiving}>
                  <FormLabel htmlFor="isLiving" mb={4} fontWeight={`bold`}>
                    現在の状況
                  </FormLabel>
                  <Controller
                    control={control}
                    name="isLiving"
                    rules={{ required: "物件の種類を選択してください" }}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <Wrap spacing={8}>
                          <WrapItem>
                            <Radio size={`md`} bg={`white`} value="居住中">
                              居住中
                            </Radio>
                          </WrapItem>
                          <WrapItem>
                            <Radio
                              size={`md`}
                              bg={`white`}
                              value="空家（空地）"
                            >
                              空家（空地）
                            </Radio>
                          </WrapItem>
                        </Wrap>
                      </RadioGroup>
                    )}
                  />
                  <FormErrorMessage mt={4}>
                    {errors.kind && errors.kind.message}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </Stack>
            <Stack align={`center`} py={{ base: 4, lg: 12 }}>
              <Button
                color={`white`}
                bg={`blue.500`}
                size={`lg`}
                fontSize={{ base: 14, lg: 16 }}
                rounded={40}
                _hover={{ bg: "blue.400", color: "white" }}
                type={`submit`}
                mt={{ base: 2, lg: 0 }}
                mb={8}
                isLoading={isSubmitting}
                w={{ base: "85%", lg: `40%` }}
              >
                送信する
              </Button>
            </Stack>
          </Box>
        </FormProvider>
      </Stack>
    </Stack>
  )
}

export default SellingForm
